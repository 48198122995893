<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">{{ $t('title.BasicInformation') }}</el-col>
    </el-row>
    <el-form ref="addOutboundForm" label-width="100px">
      <div>
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="仓库名称">
              {{ basicInfor.logicWarehouseName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="所属物理仓">
              {{ basicInfor.sourceWarehouseName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="可用库存">
              {{ basicInfor.availableQuantity }}</el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-row class="grid-content bg-purple-dark" type="flex">
      <span class="bg-purple-title">{{ $t('title.InventoryDetails') }}</span>
    </el-row>
    <el-form ref="addOutboundForm" :model="outboundForm" label-width="80px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="SKU">
              <el-input v-model="outboundForm.sku" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="Style">
              <el-input v-model="outboundForm.style" />
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <el-form-item :class="$i18n.locale" label="" />
          </el-col>
          <el-col :span="5">
            <el-button type="primary" @click="_getWarehouseDetail()">{{ $t('page.search') }}</el-button>
            <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableData"
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="sku" label="SKU" align="center" />
      <el-table-column prop="style" label="Style" align="center" />
      <el-table-column prop="color" label="Color" align="center" />
      <el-table-column prop="size" label="Size" align="center" />
      <el-table-column prop="availableQuantity" label="可用库存" align="center">
        <template slot-scope="scope">
          <el-link type="primary" @click="_getPagedetailsOpern(scope.row)">
            {{ scope.row.availableQuantity }}
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <Paging :pager="pager" @update="update" />
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
    <DetailsPopup
      is-show-batch-no
      :dialog-visible="dialogVisible"
      :details-popup-loading="detailsPopupLoading"
      :details-popup-list="detailsPopupList"
      :details-popup-obj="detailsPopupObj"
      :details-popup-pager="detailsPopupPager"
      :details-popup-column="detailsPopupColumn"
      :details-popup-base-infor="detailsPopupBaseInfor"
      @getPageList="_getPagedetailsPopup"
      @offDialogVisible="offDialogVisible"
    />
  </div>
</template>
<script>
import Paging from '@/components/Paging'
import { getWarehouseDetail } from '@/api/inventory-inquiry'
import DetailsPopup from '@/components/DetailsPopup'
import { skuAvailableStock } from '@/api/stockin'

export default {
  components: {
    Paging,
    DetailsPopup
  },
  data() {
    return {
      basicInfor: {},
      outboundForm: {
        sku: '',
        style: ''
      },
      tableLoading: false,
      pager: {
        size: 100,
        current: 1,
        total: 0
      },
      tableData: [],
      detailsPopupList: [],
      detailsPopupObj: {},
      dialogVisible: false,
      detailsPopupLoading: false,
      detailsPopupPager: {
        size: 20,
        current: 1,
        total: 0
      },
      detailsPopupBaseInfor: [
        {
          title: '仓库名称',
          key: 'logicWarehouseName'
        }, {
          title: '所属物理仓',
          key: 'sourceWarehouseName'
        }, {
          title: '货主',
          key: 'cargoOwnerCode'
        }, {
          title: '可用库存',
          key: 'availableQuantity'
        }
      ],
      detailsPopupColumn: [
        {
          title: '入库批次号',
          key: 'batchNo'
        },
        {
          title: '入库时间',
          key: 'inboundTime'
        },
        {
          title: '可用库存',
          key: 'pairs'
        }
      ]
    }
  },
  computed: {
    queryParams() {
      const { logicWarehouseCode } = this.$route.query
      return Object.assign({}, this.pager, this.outboundForm, { logicWarehouseCode, availableQuantity: this.basicInfor.availableQuantity })
    }
  },
  mounted() {
    this._getWarehouseDetail()

    const { row } = this.$route.query
    this.basicInfor = row && JSON.parse(row) || {}
  },
  methods: {
    async _getWarehouseDetail(flag) {
      try {
        this.tableLoading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records }} = await getWarehouseDetail(this.queryParams)
        this.pager = pager
        this.tableData = records
      } finally {
        this.tableLoading = false
      }
    },
    update(val) {
      this.pager = val
      this._getWarehouseDetail()
    },
    cancle() {
      this.$router.go(-1)
    },
    resetClick() {
      this.outboundForm = {
        sku: '',
        style: ''
      }
      this._getWarehouseDetail(1)
    },
    _getPagedetailsOpern(row) {
      const obj = { ...this.basicInfor, pairs: row.availableQuantity, ...row }
      this.detailsPopupList = []
      this.detailsPopupObj = obj
      this.dialogVisible = true
      this.detailsPopupLoading = true
      this._getPagedetailsPopup(obj)
    },
    async _getPagedetailsPopup(row) {
      try {
        const { datas } = await skuAvailableStock(row)
        this.detailsPopupLoading = false
        if (datas && Object.keys(datas).length) {
          const { records, pager } = datas
          this.detailsPopupList = records
          this.detailsPopupPager = pager
        }
      } finally {
        this.detailsPopupLoading = false
      }
    },
    offDialogVisible() {
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="scss" scope>

</style>
